<template>
	<div class="balance_content">
		<a-table
			class="balance_table"
			:row-key="record => record.id"
			:columns="columns"
			:data-source="list"
			:pagination="pagination"
			:loading="loading"
			@change="handleTableChange"
			:scroll="{ x: 1200 }">
			<div slot="cell_type" slot-scope="text">
				{{ formate_type(text) }}
			</div>
			<div slot="cell_status" slot-scope="text">
				{{ formate_status(text) }}
			</div>
			<div slot="cell_total" slot-scope="text">
				{{ formate_count(text) }}
			</div>
			<div slot="cell_discount" slot-scope="text">
				{{ formate_count(text) }}
			</div>
			<div slot="cell_pay" slot-scope="text">
				{{ formate_count(text) }}
			</div>
			<div slot="cell_pay" slot-scope="text">
				{{ formate_count(text) }}
			</div>
			<div
				slot="cell_quxiao"
				v-if="record.status == 0"
				@click="quxiaod(record)"
				class="dingdan"
				slot-scope="record">
				取消订单
			</div>
		</a-table>
	</div>
</template>
<script>
import { order_index } from "@/api/const_manage";
const columns = [
	{
		title: "订单号",
		dataIndex: "trade_no",
	},
	{
		title: "订单类型",
		dataIndex: "type",
		scopedSlots: { customRender: "cell_type" },
	},
	{
		title: "商品名称",
		dataIndex: "subject",
	},
	{
		title: "订单状态",
		dataIndex: "status",
		scopedSlots: { customRender: "cell_status" },
	},
	{
		title: "数量",
		dataIndex: "purchase_num",
	},
	{
		title: "订单金额",
		dataIndex: "total_amount",
		scopedSlots: { customRender: "cell_total" },
	},
	{
		title: "优惠金额",
		dataIndex: "discount_amount",
		scopedSlots: { customRender: "cell_discount" },
	},
	{
		title: "实付金额",
		dataIndex: "pay_amount",
		scopedSlots: { customRender: "cell_pay" },
	},
	{
		title: "付款时间",
		dataIndex: "payment_at",
	},
	{
		title: "操作",
		// dataIndex: "status",
		scopedSlots: { customRender: "cell_quxiao" },
	},
];
import { coupon_order } from "@/api/const_manage";
export default {
	name: "balance_fee",
	data() {
		return {
			columns,
			list: [],
			loading: false,
			pagination: {
				pageNum: 1, //当前页数
				pageSize: 20, //每页条数
				total: 0,
				showTotal: total => `共 ${total} 条`, // 显示总数
			},
		};
	},
	mounted() {
		this.fetchList();
	},
	methods: {
		async quxiaod(record) {
			let { data } = await coupon_order({
				token: localStorage.getItem("token"),
				id: record.id,
			});
			if (data.code == 200) {
				this.$message.success("订单取消成功！");
				this.fetchList();
			}
		},
		async fetchList() {
			this.loading = true;
			const { data } = await order_index({
				pagesize: 20,
				page: this.pagination.pageNum,
			});

			if (data.code == 200) {
				this.pagination.total = data.data.total;
				this.loading = false;

				this.list = data.data.list;
			}
		},
		handleTableChange(pagination) {
			this.pagination.pageNum = pagination.current;

			this.fetchList();
		},
		formate_type: function (text) {
			const typeMap = {
				0: "购买设备",
				1: "订单类型",
				6: "余额充值",
				7: "佣金提现",
			};
			return typeMap[text];
		},
		formate_status: function (text) {
			const statusMap = {
				0: "未支付",
				1: "已支付",
				2: "已取消",
			};
			return statusMap[text];
		},
		formate_count: function (text) {
			return `¥ ${text}`;
		},
	},
};
</script>
<style scoped lang="less">
.balance_content {
	.balance_table {
		margin-top: 20px;
		margin-left: 15px;
	}
}
.dingdan {
	cursor: pointer;
	color: #4c84ff;
}
</style>
